import { useEffect, useState } from "react";
// import { /* NavLink as NavLinkRRD,*/ Link } from "react-router-dom";
import { LOGOUT } from "urls";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
  // NavbarBrand,
  // Badge,
} from "reactstrap";
import { useAuth } from "store/auth-context";
import { shortName } from "helpers/utils";
import { refreshFecth } from "store/refresh-request";
import { useUser } from "store/user-context";




const useLogout = () => {
  const [logout, setLogout] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    if (logout) {
      const fetchForLogout = async () => {
        const response = await fetch(LOGOUT, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + auth.accessToken,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ refresh_token: auth.refreshToken }),
        });
        if (!response.ok) {
          if(response.status !== 401 ) throw new Error(`Network response was not OK . Status = ${response.status}`);
          refreshFecth(auth);
          return
        }
        setLogout(false);
        auth.logout();
      }

      fetchForLogout();
    }
  }, [logout, auth]);

  return setLogout
}

const AdminNavbar = (props) => {

  const { user } = useUser()

  const {email, fullName, photo} = user;

  const logout = useLogout();

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          {/* {!isOpen && <NavbarBrand className="pt-0">
            <img
              alt=''
              className="navbar-brand-img"
              src={
                require("assets/img/logo/white.svg")
                  .default
              }
              height={36}
            />
          </NavbarBrand>} */}

          <Nav className="align-items-center d-none d-md-flex ml-auto mr-0" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  {(photo) ? <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={photo}
                    />
                  </span> :
                    <span className="profile-rounded-circle text-white">
                      {shortName(fullName ?? email)}
                    </span>
                  }
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {fullName ?? email}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                {/* <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem> */}
                {/* <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem> */}
                {/* <DropdownItem divider /> */}
                <DropdownItem href="#" onClick={(e) => {
                  e.preventDefault();
                  logout(true);
                }}>
                  <i className="ni ni-user-run" />
                  <span>Çıxış</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
