import React from "react";
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import { CITY, ALL, VILLAGE } from "variables/company";

// const company = {
//   type: VILLAGE
// }

const DeviceCard = ({ device, company }) => {

  let temperatureIcon = 'fas fa-temperature-high';

  if (device.currentindicator) {
    if (device.currentindicator.temperature >= 30) temperatureIcon = 'fas fa-temperature-hot';
    else if (device.currentindicator.temperature < 30 && device.currentindicator.temperature >= 18) temperatureIcon = 'fas fa-temperature-high';
    else if (device.currentindicator.temperature < 18 && device.currentindicator.temperature > 0) temperatureIcon = 'fas fa-temperature-low';
    else if (device.currentindicator.temperature <= 0) temperatureIcon = 'fas fa-temperature-frigid';
  }

  let cardList = [
    <Card className="card-stats mb-4 mb-xl-0 mr-2 ml-2" key={device.imei + 'temperature'}>
      <CardBody>
        <Row>
          <Col>
            <CardTitle
              tag="h5"
              className="text-uppercase text-muted mb-0"
            >
              Temperatur
            </CardTitle>
            <span className="h2 font-weight-bold mb-0 text-danger">
              {device.currentindicator?.temperature} &#8451;
            </span>
          </Col>
          <Col className="col-auto">
            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
              <i className={temperatureIcon} />
            </div>
          </Col>
        </Row>
        <p className="mt-3 mb-0 text-muted text-sm">
          <span className="text-nowrap">{device.name} - {device.imei}</span>
        </p>
      </CardBody>
    </Card>,
    <Card className="card-stats mb-4 mb-xl-0 ml-2 mr-2" key={device.imei + 'humidity'}>
      <CardBody>
        <Row>
          <Col>
            <CardTitle
              tag="h5"
              className="text-uppercase text-muted mb-0"
            >
              Rütubət
            </CardTitle>
            <span className="h2 font-weight-bold mb-0 text-info">{device.currentindicator?.humidity} &#37;</span>
          </Col>
          <Col className="col-auto">
            <div className="icon icon-shape bg-info text-white rounded-circle shadow">
              <i className="fas fa-humidity" />
            </div>
          </Col>
        </Row>
        <p className="mt-3 mb-0 text-muted text-sm">
          <span className="text-nowrap">{device.name} - {device.imei}</span>
        </p>
      </CardBody>
    </Card>,
  ];

  if (company.type === CITY || company.type === ALL) {
    cardList = cardList.concat([
      <Card className="card-stats mb-4 mb-xl-0 ml-2 mr-2" key={device.imei + 'magnet'}>
        <CardBody>
          <Row>
            <Col>
              <CardTitle
                tag="h5"
                className="text-uppercase text-muted mb-0"
              >
                Magnit
              </CardTitle>
              <span className="h2 font-weight-bold mb-0 text-primary">{device.currentindicator?.magnet} mG</span>
            </Col>
            <Col className="col-auto">
              <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                <i className="fas fa-magnet" />
              </div>
            </Col>
          </Row>
          <p className="mt-3 mb-0 text-muted text-sm">
            <span className="text-nowrap">{device.name} - {device.imei}</span>
          </p>
        </CardBody>
      </Card>,
      <Card className="card-stats mb-4 mb-xl-0 mr-2 ml-2" key={device.imei + 'carbonmonoksid'}>
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle
                tag="h5"
                className="text-uppercase text-muted mb-0"
              >
                Karbon-Monoksid
              </CardTitle>
              <span className="h2 font-weight-bold mb-0 text-darker">{device.currentindicator?.carbonmonoksid} PPM</span>
            </div>
            <Col className="col-auto">
              <div className="icon icon-shape bg-darker text-white rounded-circle shadow">
                <img
                  alt="..."
                  src={
                    require("assets/img/icons/carbon-monoxide.png")
                      .default
                  }
                />
              </div>
            </Col>
          </Row>
          <p className="mt-3 mb-0 text-muted text-sm">
            <span className="text-nowrap">{device.name} - {device.imei}</span>
          </p>
        </CardBody>
      </Card>,
      <Card className="card-stats mb-4 mb-xl-0 mr-2 ml-2" key={device.imei + 'carbondioksid'}>
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle
                tag="h5"
                className="text-uppercase text-muted mb-0"
              >
                Karbon-Dioksid
              </CardTitle>
              <span className="h2 font-weight-bold mb-0 text-default">
                {device.currentindicator?.carbondioksid} PPM
              </span>
            </div>
            <Col className="col-auto">
              <div className="icon icon-shape bg-default text-white rounded-circle shadow">
                <img
                  alt="..."
                  src={
                    require("assets/img/icons/co2-1.png")
                      .default
                  }
                />
              </div>
            </Col>
          </Row>
          <p className="mt-3 mb-0 text-muted text-sm">
            <span className="text-nowrap">{device.name} - {device.imei}</span>
          </p>
        </CardBody>
      </Card>,
      <Card className="card-stats mb-4 mb-xl-0 mr-2 ml-2" key={device.imei + 'ozon'}>
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle
                tag="h5"
                className="text-uppercase text-muted mb-0"
              >
                Ozon
              </CardTitle>
              <span className="h2 font-weight-bold mb-0 text-cyan">
                {device.currentindicator?.ozon} PPM
              </span>
            </div>
            <Col className="col-auto">
              <div className="icon icon-shape bg-cyan text-white rounded-circle shadow">
                <img
                  alt="..."
                  src={
                    require("assets/img/icons/ozone.png")
                      .default
                  }
                />
              </div>
            </Col>
          </Row>
          <p className="mt-3 mb-0 text-muted text-sm">
            <span className="text-nowrap">{device.name} - {device.imei}</span>
          </p>
        </CardBody>
      </Card>,
      <Card className="card-stats mb-4 mb-xl-0 mr-2 ml-2" key={device.imei + 'ammonia'}>
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle
                tag="h5"
                className="text-uppercase text-muted mb-0"
              >
                Ammonyak
              </CardTitle>
              <span className="h2 font-weight-bold mb-0 text-warning">{device.currentindicator?.ammonia} PPM</span>
            </div>
            <Col className="col-auto">
              <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                <img
                  alt="..."
                  src={
                    require("assets/img/icons/mouthwash.png")
                      .default
                  }
                />
              </div>
            </Col>
          </Row>
          <p className="mt-3 mb-0 text-muted text-sm">
            <span className="text-nowrap">{device.name} - {device.imei}</span>
          </p>
        </CardBody>
      </Card>,
      <Card className="card-stats mb-4 mb-xl-0 mr-2 ml-2" key={device.imei + 'azotdioksid'}>
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle
                tag="h5"
                className="text-uppercase text-muted mb-0"
              >
                Azot-Dioksid
              </CardTitle>
              <span className="h2 font-weight-bold mb-0 text-yellow">{device.currentindicator?.azotdioksid} PPM</span>
            </div>
            <Col className="col-auto">
              <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                <img
                  alt="..."
                  src={
                    require("assets/img/icons/nitrous-oxide.png")
                      .default
                  }
                />
              </div>
            </Col>
          </Row>
          <p className="mt-3 mb-0 text-muted text-sm">
            <span className="text-nowrap">{device.name} - {device.imei}</span>
          </p>
        </CardBody>
      </Card>,
      <Card className="card-stats mb-4 mb-xl-0 mr-2 ml-2" key={device.imei + 'metan'}>
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle
                tag="h5"
                className="text-uppercase text-muted mb-0"
              >
                Metan
              </CardTitle>
              <span className="h2 font-weight-bold mb-0 text-success">{device.currentindicator?.metan} PPM</span>
            </div>
            <Col className="col-auto">
              <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                <img
                  alt="..."
                  src={
                    require("assets/img/icons/ch4.png")
                      .default
                  }
                />
              </div>
            </Col>
          </Row>
          <p className="mt-3 mb-0 text-muted text-sm">
            <span className="text-nowrap">{device.name} - {device.imei}</span>
          </p>
        </CardBody>
      </Card>,
      <Card className="card-stats mb-4 mb-xl-0 ml-2 mr-2" key={device.imei + 'sound'}>
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle
                tag="h5"
                className="text-uppercase text-muted mb-0"
              >
                Səs
              </CardTitle>
              <span className="h2 font-weight-bold mb-0 text-indigo">{device.currentindicator?.sound} dB</span>
            </div>
            <Col className="col-auto">
              <div className="icon icon-shape bg-indigo text-white rounded-circle shadow">
                <i className="fas fa-volume-up" />
              </div>
            </Col>
          </Row>
          <p className="mt-3 mb-0 text-muted text-sm">
            <span className="text-nowrap">{device.name} - {device.imei}</span>
          </p>
        </CardBody>
      </Card>,
      <Card className="card-stats mb-4 mb-xl-0 mr-2 ml-2" key={device.imei + 'pm2_5'}>
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle
                tag="h5"
                className="text-uppercase text-muted mb-0"
              >
                PM 2.5
              </CardTitle>
              <span className="h2 font-weight-bold mb-0 text-dark">{device.currentindicator?.pm2_5} µg/m<sup>3</sup></span>
            </div>
            <Col className="col-auto">
              <div className="icon icon-shape bg-dark text-white rounded-circle shadow">
                <img
                  alt="..."
                  src={
                    require("assets/img/icons/air-pollution.png")
                      .default
                  }
                />
              </div>
            </Col>
          </Row>
          <p className="mt-3 mb-0 text-muted text-sm">
            <span className="text-nowrap">{device.name} - {device.imei}</span>
          </p>
        </CardBody>
      </Card>,
      <Card className="card-stats mb-4 mb-xl-0 mr-2 ml-2" key={device.imei + 'pm10'}>
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle
                tag="h5"
                className="text-uppercase text-muted mb-0"
              >
                PM 10
              </CardTitle>
              <span className="h2 font-weight-bold mb-0 text-pink">{device.currentindicator?.pm10} µg/m<sup>3</sup></span>
            </div>
            <Col className="col-auto">
              <div className="icon icon-shape bg-pink text-white rounded-circle shadow">
                <img
                  alt="..."
                  src={
                    require("assets/img/icons/air-pollution-2.png")
                      .default
                  }
                />
              </div>
            </Col>
          </Row>
          <p className="mt-3 mb-0 text-muted text-sm">
            <span className="text-nowrap">{device.name} - {device.imei}</span>
          </p>
        </CardBody>
      </Card>
    ])
  }

  if (company.type === ALL || company.type === VILLAGE) {
    cardList = cardList.concat([
      <Card className="card-stats mb-4 mb-xl-0 ml-2 mr-2" key={device.imei + 'wind'}>
        <CardBody>
          <Row>
            <Col>
              <CardTitle
                tag="h5"
                className="text-uppercase text-muted mb-0"
              >
                Külək
              </CardTitle>
              <span className="h2 font-weight-bold mb-0 text-cyan">{device.currentindicator?.wind} m/san</span>
            </Col>
            <Col className="col-auto">
              <div className="icon icon-shape bg-cyan text-white rounded-circle shadow">
                <i className="fas fa-wind" />
              </div>
            </Col>
          </Row>
          <p className="mt-3 mb-0 text-muted text-sm">
            <span className="text-nowrap">{device.name} - {device.imei}</span>
          </p>
        </CardBody>
      </Card>,
      <Card className="card-stats mb-4 mb-xl-0 mr-2 ml-2" key={device.imei + 'rain'}>
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle
                tag="h5"
                className="text-uppercase text-muted mb-0"
              >
                Yağış
              </CardTitle>
              <span className="h2 font-weight-bold mb-0 text-dark">{device.currentindicator?.rain} mm</span>
            </div>
            <Col className="col-auto">
              <div className="icon icon-shape bg-dark text-white rounded-circle shadow">
                <i className="fas fa-cloud-rain" />
              </div>
            </Col>
          </Row>
          <p className="mt-3 mb-0 text-muted text-sm">
            <span className="text-nowrap">{device.name} - {device.imei}</span>
          </p>
        </CardBody>
      </Card>
    ])
  }

  return cardList
}

export default DeviceCard;
