export const shortName = (value) => {
  let name = '';
  if (value) {
    const fn = value.split(" ");
    for (let i = 0; i < fn.length; i++) {
      if (i < 2) {
        name += fn[i].toUpperCase().charAt(0);
      }
    }
  }
  return name;
}

export const windDirection = (dir) => {
  const dirNum = Math.round(dir / 22.5);
  switch (dirNum) {
    case 0:
      return "Şimal";
    case 1:
      return "Şimal Şimal-Şərq";
    case 2:
      return "Şimal-Şərq";
    case 3:
      return "Şərq Şimal-Şərq";
    case 4:
      return "Şərq";
    case 5:
      return "Şərq Cənub-Şərq";
    case 6:
      return "Cənub-Şərq";
    case 7:
      return "Cənub Cənub-Şərq";
    case 8:
      return "Cənub";
    case 9:
      return "Cənub Cənub-Qərb";
    case 10:
      return "Cənub-Qərb";
    case 11:
      return "Qərb Cənub-Qərb";
    case 12:
      return "Qərb";
    case 13:
      return "Qərb Şimal-Qərb";
    case 14:
      return "Şimal-Qərb";
    case 15:
      return "Şimal Şimal-Qərb";
    case 16:
      return "Şimal";
    default:
      return "";
  }
}
