import React from "react"
import Header from "components/Headers/Header"
import {
  Card,
  CardBody,
} from 'reactstrap'
import SmallTable from "components/Device/Table"
import Detail from "components/Device/Detail"
import BreadCrumb from "components/Device/BreadCrumb"

const DeviceDetail = (props) => {

  return (
    <>
      <Header />
      <Card>
        <BreadCrumb />
        <CardBody>
          <Detail />
          <SmallTable />
        </CardBody>
      </Card>
    </>
  )
}


export default DeviceDetail;
