import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  //CardTitle,
  Row,
  Col,
  CardHeader
} from 'reactstrap';
import { useAuth } from 'store/auth-context';
import { WEATHER_API, ICON_URL } from 'urls';
import { refreshFecth } from 'store/refresh-request';
import Carousel from "react-multi-carousel";
import { windDirection } from 'helpers/utils';

const useWeather = (imei) => {
  const [data, setData] = useState(null);
  const auth = useAuth();
  useEffect(() => {
    if(!imei) return;
    const fetchWeather = async () => {
      const response = await fetch(`${WEATHER_API + imei}/`, {
        headers: {
          'Authorization': 'Bearer ' + auth.accessToken,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          refreshFecth(auth);
          return;
        } else {
          throw new Error(`Network response was not OK . Status = ${response.status}`);
        }
      }

      const data = await response.json();

      setData(data);
    }

    fetchWeather();
  }, [auth, imei]);

  return data;
}

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};

const Weather = ({imei, bigDiv}) => {
  const data = useWeather(imei);

  let wind;
  if(data?.wind) {
    wind = windDirection(data.wind.deg);
  }

  return (
    data && <Card className='bg-gradient-primary'>
      <CardHeader className='bg-transparent text-white'>
        Cihazın yerləşdiyi əraziyə uyğun hava proqnozu
      </CardHeader>
      <CardBody className={bigDiv ? 'pb-md-5 pt-md-5' : ''}>
        <Row>
          <Col xl="3" md="4" className="text-center">
            <div className="bg-transparent text-primary rounded-circle p-0">
              <img
                alt="..."
                src={ICON_URL + data?.weather[0].icon + '@2x.png'}
              />
            </div>
            <small className='text-white'>{data?.name}, {data?.weather[0].description}</small>
          </Col>
          <Col xl={9} md={8}>
            <Carousel
              responsive={responsive}
              swipeable={false}
              draggable={false}
              showDots={false}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              arrows={false}
              focusOnSelect={true}
            >
              <Card className='bg-transparent border-0 shadow-0 p-0'>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <p className='text-white'><b>Temperatur: </b>{data?.main.temp.toFixed(1)} &#8451;</p>
                      <p className='text-white'><b>Max: </b>{data?.main.temp_max.toFixed(1)} &#8451;</p>
                    </Col>
                    <Col md="6">
                      <p className='text-white'><b>Min: </b>{data?.main.temp_min.toFixed(1)} &#8451;</p>
                      <p className='text-white'><b>Hiss olunur: </b>{data?.main.feels_like.toFixed(1)} &#8451;</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className='bg-transparent border-0 shadow-0 p-0'>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <p className='text-white'><b>Təzyiq: </b>{data?.main.pressure} qr/sm<sup>2</sup></p>
                      <p className='text-white'><b>Rütubət: </b>{data?.main.humidity} &#37;</p>
                    </Col>
                    {data?.main.sea_level &&
                    <Col md="6">
                      <p className='text-white'><b>Dəniz səviyyəsi: </b>{data?.main.sea_level} m</p>
                    </Col>}
                  </Row>
                </CardBody>
              </Card>
              <Card className='bg-transparent border-0 shadow-0 p-0'>
                <CardBody>
                  <Row>
                    <Col>
                      <p className='text-white'><b>Külək: </b>{data?.wind.speed.toFixed(1)} m/san</p>
                      <p className='text-white'>{wind} küləyi</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className='bg-transparent border-0 shadow-0 p-0'>
                <CardBody>
                  <Row>
                    <Col>
                      <p className='text-white'><b>Gün çıxır: </b>{data?.sys.sunrise}</p>
                      <p className='text-white'><b>Gün batır: </b>{data?.sys.sunset}</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Carousel>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}


export default Weather;
