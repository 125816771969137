import React from 'react';
import { useSelector } from 'react-redux';
import { getTableHeader } from 'helpers/table';

import {
  Card,
  CardHeader,
  Table,
  Row,
  Col,
} from 'reactstrap';


const DataTable = ({count, results}) => {
  const activeTable = useSelector(state => state.table.active)

  const {tableContent, header, tableHeader} = getTableHeader(activeTable, results);

  return (
    <Card className='bg-default mt-3'>
      <CardHeader className='text-white bg-transparent border-0'>
        <Row>
          <Col>
            {header}
          </Col>
          <Col>
            <div className="float-right clear-fix">
              <span className="text-bold">{(count) ? count : 0} Nəticə</span>
            </div>
          </Col>
        </Row>
      </CardHeader>
      {results && <Table className="align-items-center table-transparent table-flush text-white" responsive>
        <thead className="thead-transparent" >
          {tableHeader}
        </thead>
        <tbody className='list'>
          {tableContent}
        </tbody>
      </Table>}
    </Card>
  );
}


export default DataTable;
