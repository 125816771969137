import { useEffect, useState } from "react";
import { DEVICE_LIST, DATA_URL } from "urls";
import { useAuth } from "./auth-context";
import { refreshFecth } from "./refresh-request";
import { useDispatch } from "react-redux";
import { deviceActions } from "./device-slice";
import { useParams } from "react-router-dom";



export const useDevice = () => {
  const auth = useAuth()
  const dispacth = useDispatch()

  useEffect(() => {

    const fetchData = async () => {
      const headers = {
        'Authorization': 'Bearer ' + auth.accessToken,
        'Content-Type': 'application/json',
      }
      const response = await fetch(DEVICE_LIST, {
        headers: headers,
      })

      if (!response.ok) {
        throw new Error(`Network response was not OK . Status = ${response.status}`)
      }
      const deviceData = await response.json();
      dispacth(deviceActions.setDevices(deviceData));
    }

    fetchData();
  }, [auth, dispacth]);

  return;
}


export const useDeviceDetail = () => {
  const {imei} = useParams()
  const auth = useAuth()
  const dispacth = useDispatch()
  const [state, setState] = useState({
    device: null,
    currentIndicator: null,
    yesterdayIndicator: null,
  })

  useEffect(() => {

    const fetchDevice = async () => {
      const response = await fetch(DATA_URL + imei + '/', {
        headers: {
          'Authorization': 'Bearer ' + auth.accessToken,
          'Content-Type': 'application/json',
        },
      })

      if (!response.ok) {
        if (response.status === 401) {
          refreshFecth(auth);
          return;
        } else {
          throw new Error(`Network response was not OK . Status = ${response.status}`);
        }
      }

      const data = await response.json();

      const device = {...data.currentIndicator.device}
      const currentIndicator = {...data.currentIndicator}
      delete currentIndicator['device']

      setState({
        device,
        currentIndicator,
        yesterdayIndicator: data.yesterdayIndicator,
      })

      dispacth(deviceActions.updateDevice({
        ...device,
        currentindicator: currentIndicator
      }))

    }

    fetchDevice();
  }, [auth, dispacth, imei]);

  return state
}
