import { configureStore } from '@reduxjs/toolkit';

import tableReducer from './table-slice';
import deviceReducer from './device-slice';

const store = configureStore({
  reducer: {
    table: tableReducer,
    device: deviceReducer,
  }
});

export default store;
