import React, { useCallback, Fragment } from "react";
import ReactDatetime from "react-datetime";
import moment from 'moment';
import "moment/locale/az";
import { Button } from "reactstrap";

import {
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Row
} from "reactstrap";

moment.locale('az');

const capitalize = (str) => {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
}

const getCapitalizeDate = (date = null) => {
  if (!date) return ''
  return date.format('D MMMM YYYY, H:mm')
    .split(' ')
    .map(text => capitalize(text))
    .join(' ')
}

const DatePicker = (props) => {

  const { start, end, onStartChange, onEndChange } = props;

  const startDate = (start) ? moment(Date.parse(start)).locale('az') : null;

  const endDate = (end) ? moment(Date.parse(end)).locale('az') : null;

  const disableFuture = useCallback(current => {
    const today = moment().locale('az');
    return current.isBefore(today);
  }, []);

  const checkStartDate = current => {
    if (endDate) {
      if (endDate.format('YYYY-MM-DD HH:mm') < current.format('YYYY-MM-DD HH:mm')) return false;
    }
    return disableFuture(current);
  }

  const checkEndDate = current => {
    if (startDate) {
      if (current.format('YYYY-MM-DD HH:mm') < startDate.format('YYYY-MM-DD HH:mm')) return false;
    }
    return disableFuture(current);
  }

  return (
    <Fragment>
      <Row>
        <Col md={6}>
          <FormGroup>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
              <ReactDatetime
                inputProps={{
                  placeholder: "Başlama tarixi",
                  value: getCapitalizeDate(startDate),
                }}
                isValidDate={checkStartDate}
                timeFormat='HH:mm'
                dateFormat='YYYY-MM-DD'
                value={start}
                renderDay={(props, currentDate, selectedDate) => {
                  let classes = props.className;
                  if (
                    startDate &&
                    endDate &&
                    startDate.format('YYYY-MM-DD HH:mm') === currentDate.format('YYYY-MM-DD HH:mm')
                  ) {
                    if (startDate.format('YYYY-MM-DD HH:mm') !== endDate.format('YYYY-MM-DD HH:mm')) classes += " start-date";
                  } else if (
                    startDate &&
                    endDate &&
                    startDate.format('YYYY-MM-DD HH:mm') <
                    currentDate.format('YYYY-MM-DD HH:mm') &&
                    endDate.format('YYYY-MM-DD HH:mm') >
                    currentDate.format('YYYY-MM-DD HH:mm')
                  ) {
                    classes += " middle-date";
                  } else if (
                    startDate &&
                    endDate &&
                    endDate.format('YYYY-MM-DD HH:mm') === currentDate.format('YYYY-MM-DD HH:mm')
                  ) {
                    classes += " end-date";
                  }


                  let disabled = false;

                  if (endDate && endDate.format('YYYY-MM-DD HH:mm') < currentDate.format('YYYY-MM-DD HH:mm')) {
                    classes += " rdtDisabled";
                    disabled = true;
                  } else if (currentDate.format('YYYY-MM-DD HH:mm') > moment(new Date()).locale('az').format('YYYY-MM-DD HH:mm')) {
                    classes += " rdtDisabled";
                    disabled = true;
                  }
                  return (
                    <td {...props} className={classes} disabled={disabled}>
                      {currentDate.date()}
                    </td>
                  );
                }}
                onChange={onStartChange}

              />
              {start && <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <Button
                    onClick={(e) => onStartChange(null)}
                    className='btn-xsm rounded-circle p-auto m-0'
                    color="secondary"
                  >
                    <i className="fa fa-times text-danger" />
                  </Button>
                </InputGroupText>
              </InputGroupAddon>}
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
              <ReactDatetime
                inputProps={{
                  placeholder: "Bitmə tarixi",
                  value: getCapitalizeDate(endDate),
                }}
                isValidDate={checkEndDate}
                timeFormat='HH:mm'
                locale="az"
                dateFormat='YYYY-MM-DD'
                value={end}
                renderDay={(props, currentDate, selectedDate) => {
                  let classes = props.className;
                  if (
                    startDate &&
                    endDate &&
                    startDate.format('YYYY-MM-DD HH:mm') === currentDate.format('YYYY-MM-DD HH:mm')
                  ) {
                    if (startDate.format('YYYY-MM-DD HH:mm') !== endDate.format('YYYY-MM-DD HH:mm')) classes += " start-date";
                  } else if (
                    startDate &&
                    endDate &&
                    startDate.format('YYYY-MM-DD HH:mm') <
                    currentDate.format('YYYY-MM-DD HH:mm') &&
                    endDate.format('YYYY-MM-DD HH:mm') >
                    currentDate.format('YYYY-MM-DD HH:mm')
                  ) {
                    classes += " middle-date";
                  } else if (
                    startDate &&
                    endDate &&
                    endDate.format('YYYY-MM-DD HH:mm') === currentDate.format('YYYY-MM-DD HH:mm')
                  ) {
                    classes += " end-date";
                  }

                  let disabled = false;

                  if (currentDate.format('YYYY-MM-DD HH:mm') > moment(new Date()).locale('az').format('YYYY-MM-DD HH:mm')) {
                    classes += " rdtDisabled";
                    disabled = true;
                  }
                  else if (startDate && startDate.format('YYYY-MM-DD HH:mm') > currentDate.format('YYYY-MM-DD HH:mm')) {
                    classes += " rdtDisabled";
                    disabled = true;
                  }


                  return (
                    <td {...props} className={classes} disabled={disabled}>
                      {currentDate.date()}
                    </td>
                  );
                }}
                onChange={onEndChange}
              />
              {end && <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <Button
                    onClick={(e) => onEndChange(null)}
                    className='btn-xsm rounded-circle btn-secondary'
                    color="secondary"
                  >
                    <i className="fa fa-times text-danger" />
                  </Button>
                </InputGroupText>
              </InputGroupAddon>}
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
    </Fragment>
  );
}

export default DatePicker;
