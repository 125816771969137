
import React, { useCallback, useState } from 'react';
import Header from "components/Headers/Header";
// import { useDeviceList } from 'store/device-context';
import { useSelector } from 'react-redux';
import { TEMPERATURE, CO, NH, VOICE, WIND } from 'store/table-slice';
import { Link } from 'react-router-dom';
// import Device from 'components/Devices/Device';

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Table,
  Row,
  FormGroup,
  InputGroup,
  Input,
} from 'reactstrap';
import { useUser } from 'store/user-context';
import { VILLAGE } from 'variables/company';

const Devices = () => {
  const [search, setSearch] = useState(null);
  const devices = useSelector(state => state.device.devices);
  const activeTable = useSelector(state => state.table.active);
  const { company } = useUser()

  let filteredDevices = [...devices];

  if ( search ) {
    filteredDevices = filteredDevices.filter(device => {
      return device.name
              .toLowerCase()
              .includes(search.toLowerCase()) ||
             device.imei
              .toLowerCase()
              .includes(search.toLowerCase())
    });
  }

  const table1Content = useCallback((devices) => devices.map(device => (
    <tr key={'table-1-' + device.imei}>
      <th scope="row" className='text-underline'>
        <Link to={"/devices/" + device.imei} className="text-white">
          {device.name}
        </Link>
      </th>
      <td>{device.imei}</td>
      <td>{device.currentindicator?.temperature}</td>
      <td>{device.currentindicator?.humidity}</td>
      <td>{device.currentindicator?.magnet}</td>
    </tr>
  )), []);

  const table2Content = useCallback((devices) => devices.map(device => (
    <tr key={'table-2-' + device.imei}>
      <th scope="row" className='text-underline'>
        <Link to={"/devices/" + device.imei} className="text-white">
          {device.name}
        </Link>
      </th>
      <td>{device.imei}</td>
      <td>{device.currentindicator?.carbonmonoksid}</td>
      <td>{device.currentindicator?.carbondioksid}</td>
      <td>{device.currentindicator?.ozon}</td>
    </tr>
  )), []);

  const table3Content = useCallback((devices) => devices.map(device => (
    <tr key={'table-3-' + device.imei}>
      <th scope="row" className='text-underline'>
        <Link to={"/devices/" + device.imei} className="text-white">
          {device.name}
        </Link>
      </th>
      <td>{device.imei}</td>
      <td>{device.currentindicator?.ammonia}</td>
      <td>{device.currentindicator?.azotdioksid}</td>
      <td>{device.currentindicator?.metan}</td>
    </tr>
  )), []);


  const table4Content = useCallback((devices) => devices.map(device => (
    <tr key={'table-4-' + device.imei}>
      <th scope="row" className='text-underline'>
        <Link to={"/devices/" + device.imei} className="text-white">
          {device.name}
        </Link>
      </th>
      <td>{device.imei}</td>
      <td>{device.currentindicator?.sound}</td>
      <td>{device.currentindicator?.pm2_5}</td>
      <td>{device.currentindicator?.pm10}</td>
    </tr>
  )), []);

  const table5Content = useCallback((devices) => devices.map(device => (
    <tr key={'table-5-' + device.imei}>
      <th scope="row" className='text-underline'>
        <Link to={"/devices/" + device.imei} className="text-white">
          {device.name}
        </Link>
      </th>
      <td>{device.imei}</td>
      <td>{device.currentindicator?.wind}</td>
      <td>{device.currentindicator?.rain}</td>
    </tr>
  )), []);

  const tableVillageContent = useCallback((devices) => devices.map(device => (
    <tr key={'table-village-' + device.imei}>
      <th scope="row" className='text-underline'>
        <Link to={"/devices/" + device.imei} className="text-white">
          {device.name}
        </Link>
      </th>
      <td>{device.imei}</td>
      <td>{device.currentindicator?.temperature}</td>
      <td>{device.currentindicator?.humidity}</td>
      <td>{device.currentindicator?.wind}</td>
      <td>{device.currentindicator?.rain}</td>
    </tr>
  )), []);


  let tableContent, header, tableHeader, colorClass;

  if( company.type === VILLAGE) {
    tableContent = tableVillageContent(filteredDevices)
    header = "Temperatur - Rütubət - Külək - Yağış";
    tableHeader = <tr>
      <th>Cihaz Adı</th>
      <th>İMEİ</th>
      <th>Temperatur (&#8451;)</th>
      <th>Rütubət (&#37;)</th>
      <th>Külək (m/san)</th>
      <th>Yağış (mm)</th>
    </tr>;
  } else {
    switch (activeTable) {
      case TEMPERATURE:
        tableContent = table1Content(filteredDevices);
        header = "Temperatur - Rütubət - Magnit";
        // colorClass = "bg-default";
        tableHeader = <tr>
          <th>Cihaz Adı</th>
          <th>İMEİ</th>
          <th>Temperatur (&#8451;)</th>
          <th>Rütubət (&#37;)</th>
          <th>Magnit (mG)</th>
        </tr>;
        break;
      case CO:
        tableContent = table2Content(filteredDevices);
        header = <>CO - CO<sub>2</sub> - O<sub>3</sub></>;
        // colorClass = "bg-success";
        tableHeader = <tr>
          <th>Cihaz Adı</th>
          <th>İMEİ</th>
          <th>Karbon-Monoksid (PPM)</th>
          <th>Karbon-Dioksid (PPM)</th>
          <th>Ozon (PPM)</th>
        </tr>;
        break;
      case NH:
        tableContent = table3Content(filteredDevices);
        header = <>NH<sub>3</sub> - NO<sub>2</sub> - CH<sub>4</sub></>;
        // colorClass = "bg-primary";
        tableHeader = <tr>
          <th>Cihaz Adı</th>
          <th>İMEİ</th>
          <th>Ammonyak (PPM)</th>
          <th>Azot-Dioksid (PPM)</th>
          <th>Metan (PPM)</th>
        </tr>;
        break;
      case VOICE:
        tableContent = table4Content(filteredDevices);
        header = "Səs - PM2.5 - PM10";
        // colorClass = "bg-danger";
        tableHeader = <tr>
          <th>Cihaz Adı</th>
          <th>İMEİ</th>
          <th>Səs (dB)</th>
          <th>PM2.5 (µg/m<sup>3</sup>)</th>
          <th>PM10 (µg/m<sup>3</sup>)</th>
        </tr>;
        break;
      case WIND:
        tableContent = table5Content(filteredDevices);
        header = "Külək - Yağış";
        tableHeader = <tr>
          <th>Cihaz Adı</th>
          <th>İMEİ</th>
          <th>Külək (m/san)</th>
          <th>Yağış (mm)</th>
        </tr>;
        break;
      default:
        break;
    }
  }
  if(tableContent && !tableContent.length) tableContent = null;

  colorClass = "bg-default";
  return (
    <>
      <Header />
      <Container className='mt--7' fluid>
        <Card>
          <CardHeader>
            <Row>
              <Col md={6} xl={9} className="mb-1">Cihazlar</Col>
              <Col md={6} xl={3}>
                <FormGroup className=''>
                  <InputGroup className='input-group-merge input-group-sm'>
                    <div className='input-group-prepend'>
                      <span className='input-group-text'>
                        <i className='fa fa-search'></i>
                      </span>
                    </div>
                    <Input
                      type='text'
                      className=''
                      onKeyUp={(e) => setSearch(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Card className={colorClass}>
              <CardHeader className='text-white bg-transparent border-0'>
                {header}
              </CardHeader>
              <Table className="align-items-center table-transparent table-flush text-white" responsive>
                <thead className="thead-transparent" >
                  {tableHeader}
                </thead>
                <tbody className='list'>
                  {tableContent ?? <tr><td colSpan="6"><h5 className='text-center text-white'>Nəticə tapılmadı</h5></td></tr>}
                </tbody>
              </Table>
            </Card>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default Devices;
