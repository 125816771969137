import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar";
import AdminFooter from "components/Footers/AdminFooter";
import Sidebar from "components/Sidebar/Sidebar";
import { useDevice } from "store/device-fetch";
import { useUserData } from "store/user-fetch";


const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);
  useUserData()
  useDevice()

  return (
    <>
      <Sidebar
        {...props}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <div className={(isOpen) ? "main-content" : "main-content custom-main-content"} ref={mainContent}>
        <AdminNavbar
          {...props}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
        {props.children}
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
