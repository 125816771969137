import React from "react"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import {
  CardHeader,
  Breadcrumb,
  BreadcrumbItem
} from 'reactstrap'
import { Link } from "react-router-dom"

const BreadCrumb = (props) => {
  const { imei } = useParams()
  const devices = useSelector(state => state.device.devices)

  const device = devices.find(device => device.imei === imei)

  return (
    <CardHeader>
      <Breadcrumb className="breadcrumb-links breadcrumb-dark bg-transparent">
        <BreadcrumbItem>
          <Link to="/devices" className="text-default">
            <i className="fa fa-hdd mr-3"></i>
            Cihazlar
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem className="active">
          {device?.name} - {device?.imei}
        </BreadcrumbItem>
      </Breadcrumb>
    </CardHeader>
  )
}


export default BreadCrumb;
