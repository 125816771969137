import React, { useCallback, useContext, useState } from 'react'


const UserContext = React.createContext({
  user: {
    email: '',
    fullName: '',
    id: null,
    photo: null,
    phone: '',
  },
  company: {
    id: null,
    name: null,
    logo: null,
    logoSmall: null,
    type: null,
  },
  setState: ({user, company}) => {},
})


export const UserProvider = (props) => {
  const [user, setUser] = useState({
    email: '',
    fullName: '',
    id: null,
    photo: null,
    phone: '',
  });
  const [company, setCompany] = useState({
    id: null,
    name: null,
    logo: null,
    logoSmall: null,
    type: null,
  });

  const setState = useCallback(({user, company}) => {
    setUser(user)
    setCompany(company)
  }, [])

  const userContext = {
    user,
    company,
    setState,
  }

  return (
    <UserContext.Provider value={userContext}>
      {props.children}
    </UserContext.Provider>
  )
}


export const useUser = () => useContext(UserContext)

export default UserContext
