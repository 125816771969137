import { REFRESH_TOKEN } from "urls";

export const refreshFecth = async (auth) => {
  const response = await fetch(REFRESH_TOKEN, {
    method: "POST",
    headers: {
      'Authorization': 'Bearer ' + auth.accessToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      refresh: auth.refreshToken,
    })
  });

  if (!response.ok) {
    auth.logout()
    return;
  }
  const data = await response.json();
  auth.refreshReuqest(data)
}
