// import react from "react";
import AdminLayout from "layouts/Admin";
import AuthLayout from "layouts/Auth";
import { Route, Switch, Redirect } from "react-router-dom";
import { useAuth } from "store/auth-context";
// import Index from "views/Index";
import Maps from "views/Maps";
import Login from "views/Login";
import Devices from "views/Devices";
import DeviceDetail from "views/DeviceDetail";
import History from "views/History";

const App = () => {
  const auth = useAuth();
  return (
    <Switch>
      {auth.isLoggedIn ?
        <AdminLayout>
          <Switch>
            {/* <Route path="/index" component={Index} /> */}
            <Route exact path="/map" component={Maps} />
            <Route exact path="/devices" component={Devices} />
            <Route exact path="/devices/:imei" component={DeviceDetail} />
            <Route exact path="/devices/:imei/history" component={History} />
            <Redirect from="*" to="/map" />
          </Switch>
        </AdminLayout>:
      <AuthLayout>
        <Switch>
          <Route path="/login" component={Login} />
          <Redirect from="/" to="/login" />
        </Switch>
      </AuthLayout>}
    </Switch>
  )
}

export default App;
