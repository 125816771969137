import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "store/auth-context";
import { Provider } from 'react-redux';
import store from "store/redux-store";
import { UserProvider } from "store/user-context";

import "assets/plugins/nucleo/css/nucleo.css";
import "assets/plugins/fontawesome/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "assets/css/map-icons.css";
import "react-multi-carousel/lib/styles.css";
import 'mapbox-gl/dist/mapbox-gl.css';

import App from './App'

ReactDOM.render(
  <Provider store={store}>
    <AuthProvider>
      <UserProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </UserProvider>
    </AuthProvider>
  </Provider>,
  document.getElementById("root")
);
