import { createSlice } from '@reduxjs/toolkit';


const deviceSlice = createSlice({
  name: 'device',
  initialState: {
    devices: [],
  },
  reducers: {
    setDevices (state, action) {
      state.devices = action.payload;
    },
    updateDevice (state, action) {
      const index = state.devices.findIndex(item => item.id === action.payload.id)
      state.devices[index] = action.payload
    },
  }
});


export const deviceActions = deviceSlice.actions;

export default deviceSlice.reducer;
