import { createSlice } from '@reduxjs/toolkit';
export const TEMPERATURE = 'temperature', CO = 'co', NH = 'nh', VOICE = 'voice', WIND = 'wind';

const tableSlice = createSlice({
  name: 'table',
  initialState: {
    active: TEMPERATURE,
  },
  reducers: {
    tempActive (state) {
      state.active = TEMPERATURE;
    },
    coActive (state) {
      state.active = CO;
    },
    nhActive (state) {
      state.active = NH;
    },
    voiceActive (state) {
      state.active = VOICE;
    },
    windActive (state) {
      state.active = WIND;
    }
  }
});

export const tableActions = tableSlice.actions;

export default tableSlice.reducer;
