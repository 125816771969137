import React, {useState, useEffect} from 'react';
import { refreshFecth } from 'store/refresh-request';
import { DATA_URL } from 'urls';
import { useAuth } from 'store/auth-context';
import { useSelector } from 'react-redux';
import { getTableHeader, villageTable } from 'helpers/table';
import {
  Card,
  CardHeader,
  Table,
  Row,
  Col,
} from 'reactstrap';

import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useUser } from 'store/user-context';
import { VILLAGE } from 'variables/company';


const useData = (imei) => {
  const [data, setData] = useState([]);
  const auth = useAuth();
  useEffect(() => {
    if(!imei) return;
    const fetchData = async () => {
      const response = await fetch(`${DATA_URL + imei}/data/?page_size=10&page=1`, {
        headers: {
          'Authorization': 'Bearer ' + auth.accessToken,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          refreshFecth(auth);
          return;
        } else {
          throw new Error(`Network response was not OK . Status = ${response.status}`);
        }
      }

      const data = await response.json();

      setData(data.results);
    }

    fetchData();
  }, [auth, imei]);

  return data;
}



const SmallTable = () => {
  const { company } = useUser()
  const {imei} = useParams()
  const data = useData(imei);
  const activeTable = useSelector(state => state.table.active);
  const {tableContent, header, tableHeader} = (company.type === VILLAGE)? villageTable(data) :getTableHeader(activeTable, data);

  return (
    data && <Card className='bg-default mt-3'>
      <CardHeader className='text-white bg-transparent border-0'>
        <Row>
          <Col>
            {header} ( Son 10 məlumat )
          </Col>
          <Col>
            <div className="float-right clear-fix">
              <Link
                className="btn btn-sm btn-info"
                to={`/devices/${imei}/history`}
              >
                Ətraflı
              </Link>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <Table className="align-items-center table-transparent table-flush text-white" responsive>
        <thead className="thead-transparent" >
          {tableHeader}
        </thead>
        <tbody className='list'>
          {tableContent}
        </tbody>
      </Table>
    </Card>
  );
}


export default SmallTable;
