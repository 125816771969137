
import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,

  Col,
} from "reactstrap";
import { useAuth } from "store/auth-context";
import { LOGIN } from "urls";

const Login = () => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [error, setError] = useState(false);

  const auth = useAuth();

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    if (e.target.checkValidity()) setClicked(true);
  }, []);

  useEffect(() => {
    if (!clicked || !email || !password) return;

    const loginFetch = async () => {
      const response = await fetch(LOGIN, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          password,
        })
      })

      if (!response.ok) {
        setError(true);
        setPassword(null);
        setClicked(false);
        return;
      }

      const data = await response.json();

      setClicked(false);

      auth.login({
        accessToken: data.access,
        refreshToken: data.refresh,
      })
    }
    try {
      loginFetch();
    } catch (e) {
      console.log(e);
      setError(true);
      setPassword(null);
      setClicked(false);
    }

  }, [clicked, email, password, auth]);

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <h3>Daxil olun</h3>
            </div>
            {error && <div className="text-center text-danger mb-4">
              <small>Isdifadəçi adı və ya parol yanlışdır</small>
            </div>}
            <Form role="form" onSubmit={onSubmit}>
              <FormGroup className={error ? 'mb-3 has-danger' : 'mb-3'}>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className={error ? 'text-danger' : ''}>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    name="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setError(false);
                    }}
                    value={email ?? ''}
                    autoComplete="new-email"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup className={error ? 'has-danger': ''}>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className={error ? 'text-danger': ''}>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Parol"
                    type="password"
                    name="password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setError(false);
                    }}
                    value={password ?? ''}
                    autoComplete="new-password"
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit" disabled={clicked}>
                  Daxil ol
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;
