import React, { useContext, useState, useCallback } from "react";


const AuthContext = React.createContext({
  accessToken: '',
  refreshToken: '',
  isLoggedIn: false,
  login: ({ accessToken, refreshToken }) => {},
  logout: () => {},
  refreshReuqest: ({ access }) => {},
});


export const AuthProvider = (props) => {
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'));
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken'));

  const isLoggedIn = !!accessToken && !!refreshToken;

  const logoutHandler = useCallback(() => {
    setAccessToken(null);
    setRefreshToken(null);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }, []);

  const loginHandler = useCallback(({ accessToken, refreshToken }) => {
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  }, []);

  const refreshHandler = useCallback(({ access }) => {
    setAccessToken(access);
    localStorage.setItem('accessToken', access);
  }, []);

  const authContext = {
    accessToken,
    refreshToken,
    isLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    refreshReuqest: refreshHandler,
  };

  return (
    <AuthContext.Provider value={authContext}>
      {props.children}
    </AuthContext.Provider>
  )
}


export const useAuth = () => useContext(AuthContext);


export default AuthContext;
