import React from "react";
import { Card, Container, Row } from "reactstrap";
import Header from "components/Headers/Header";
// import Google from "components/Map/Google";
import MapBox from "components/Map/MapBox";


const Maps = () => {
  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow border-0">
              {/* <Google /> */}
              <MapBox />
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Maps;
